import { defineStore } from "pinia";
import Cookies from "js-cookie";

const state = () => ({
  notifications: [],
  _cookie: null,
});

const actions = {
  pushNotifications(payload) {
    this.notifications.push(payload);
  },
  popNotifications(payload) {
    this.notifications.splice(payload, 1);
  },
  setCookie(payload) {
    this._cookie = payload;
  },
  cookie_consent() {
    this._cookie = false;
    Cookies.set("accept_cookies", "true", { expires: 365 });
  },
};

const getters = {
  cookie: (state) => state._cookie,
  getNotifications: (state) => state.notifications,
};

export const useCommon = defineStore("common", {
  state,
  getters,
  actions,
});
