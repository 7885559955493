import { useFeatureFlags } from '@/store/featureFlags'
import { UnleashClient } from 'unleash-proxy-client'

export default defineNuxtPlugin(async () => {
  try {
    console.log('client side feat flags called')
    const config = useRuntimeConfig()
    const unleash = new UnleashClient({
      url: config.public.unleashUrl + '/proxy',
      clientKey: config.public.unleashProxyKey,
      appName: config.public.unleashAppName, // Set to the running environment of your application
      refreshInterval: 10,
    })

    unleash.on('update', () => {
      console.log('client side update feat flag called')
      const featureFlags = {}
      unleash.toggles.forEach((item) => {
        featureFlags[item.name] = item.enabled
      })
      useFeatureFlags().update(featureFlags)
      console.log(JSON.stringify(useFeatureFlags().getFeatureFlags))
    })
    unleash.on('ready', () => {
      console.log('client side ready feat flag called')
      if (unleash.isEnabled(config.public.unleashAppName)) {
        useFeatureFlags().update(featureFlags)
      } else {
        console.log(`${config.public.unleashAppName} is not enabled yet`)
      }
    })

    await unleash.start()
  } catch (error) {
    useFeatureFlags().update([])
    console.log(
      `client side couldn't get feature flags.Assigning empty array for feature flags ${error}`
    )
  }
})
