import { defineStore } from 'pinia';

const state = () => ({
  list: [],
});

const getters = {
  getFeatureFlags: (state: any) => state.list,
};

const actions = {
  update(featureFlags: any) {
    this.list = featureFlags;
  },
};

export const useFeatureFlags = defineStore('featureFlags', {
  state,
  getters,
  actions,
});
